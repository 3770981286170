// Styles
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';

.osrit_checkbox {
  label {
    font-size: 15px;
    cursor: pointer;
  }
  /*** custom checkboxes ***/
  input[type=checkbox] {
    display: none;
  }
  /* to hide the checkbox itself */
  input[type=checkbox]+label:before {
    font-family: FontAwesome;
    display: inline-block;
  }

  input[type=checkbox]+label:before {
    content: "\f096";
  }

  input[type=checkbox]:disabled+label:before {
    content: "\f0c8";
    color: #ccc;
  }
  /* unchecked icon */
  input[type=checkbox]+label:before {
    letter-spacing: 10px;
  }
  /* space between checkbox and label */
  input[type=checkbox]:checked+label:before {
    content: "\f046";
  }
  /* checked icon */
  input[type=checkbox]:checked+label:before {
    letter-spacing: 5px;
    color: #21a8d8;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  .error.form-control-sm.form-control {
    border: 1px solid red;
  }
}

div.osrit_date_widget {
   height: calc(1.7em + .3rem + 2px);
  width: 100%;
  border: 1px solid #ced4da;
  padding: 0.25rem 0.5rem;
  font-size: 0.810rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  font-weight: 400px;

  

  input {
    padding-left: 0;
   
  }

  .rw-filter-input,
  .rw-widget-input {
    box-shadow: none;
  }

  &.error {
    border: 1px solid #f86c6b;
  }
}